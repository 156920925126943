import React from 'react'
import { Link } from 'gatsby'

class Layout extends React.Component {
  render() {
    const { location, children, postTitle } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let crumbs

    if (location.pathname === rootPath) {
      crumbs = <></>
    } else {
      crumbs = (
        <ol className="breadcrumb text-center">
          <li>
            <Link to="/">All Posts</Link>
          </li>
          <li className="active">{postTitle}</li>
        </ol>
      )
    }
    return (
      <>
        <div className="full">
          <div className="blog jumbotron">
            <div className="container">
              <div className="col-lg-6 col-centered well text-center">
                <h1>Naomi&apos;s Blog</h1>
                {crumbs}
              </div>
            </div>
          </div>

          <svg
            id="deco-clouds2"
            className="head hidden-xs"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="100%"
            height="100"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path
              d="M-5 100 Q 0 20 5 100 Z
               M0 100 Q 5 0 10 100
               M5 100 Q 10 30 15 100
               M10 100 Q 15 10 20 100
               M15 100 Q 20 30 25 100
               M20 100 Q 25 -10 30 100
               M25 100 Q 30 10 35 100
               M30 100 Q 35 30 40 100
               M35 100 Q 40 10 45 100
               M40 100 Q 45 50 50 100
               M45 100 Q 50 20 55 100
               M50 100 Q 55 40 60 100
               M55 100 Q 60 60 65 100
               M60 100 Q 65 50 70 100
               M65 100 Q 70 20 75 100
               M70 100 Q 75 45 80 100
               M75 100 Q 80 30 85 100
               M80 100 Q 85 20 90 100
               M85 100 Q 90 50 95 100
               M90 100 Q 95 25 100 100
               M95 100 Q 100 15 105 100 Z"
            ></path>
          </svg>

          {children}

          <div className="container-fluid cloud-divider white">
            <svg
              id="deco-clouds"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="100%"
              height="100"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <path
                d="M-5 100 Q 0 20 5 100 Z
               M0 100 Q 5 0 10 100
               M5 100 Q 10 30 15 100
               M10 100 Q 15 10 20 100
               M15 100 Q 20 30 25 100
               M20 100 Q 25 -10 30 100
               M25 100 Q 30 10 35 100
               M30 100 Q 35 30 40 100
               M35 100 Q 40 10 45 100
               M40 100 Q 45 50 50 100
               M45 100 Q 50 20 55 100
               M50 100 Q 55 40 60 100
               M55 100 Q 60 60 65 100
               M60 100 Q 65 50 70 100
               M65 100 Q 70 20 75 100
               M70 100 Q 75 45 80 100
               M75 100 Q 80 30 85 100
               M80 100 Q 85 20 90 100
               M85 100 Q 90 50 95 100
               M90 100 Q 95 25 100 100
               M95 100 Q 100 15 105 100 Z"
              ></path>
            </svg>
          </div>
          <footer>
            <p>
              This website was built with{' '}
              <span style={{ color: 'red' }}>♥️</span>by{' '}
              <a
                href="https://www.oronnadiv.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Oron Nadiv
              </a>
            </p>
            <div className="page-scroll hidden-sm hidden-xs">
              <a href="#page-top" className="back-to-top">
                <i className="fa fa-angle-up"></i>
              </a>
            </div>
          </footer>
        </div>
      </>
    )
  }
}

export default Layout
