import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import moment from 'moment'

const postCard = ({ node, data }) => {
  const title = node.frontmatter.title || node.fields.slug

  return (
    <div className="blog-post row">
      <div className="img-date">
        <div className="col-md-1 text-center date-category">
          <i className="fa fa-camera fa-4x" />
          <p>{moment(node.frontmatter.date, 'MMMM DD, YYYY').format('ll')}</p>
        </div>

        <div className="img-effect">
          <Link to={node.fields.slug}>
            <Img
              className="img-responsive"
              sizes={node.frontmatter.featuredImage.childImageSharp.sizes}
            />
          </Link>
        </div>
      </div>
      <div className="col-md-12">
        <h3>
          <Link to={node.fields.slug}>{title}</Link>
        </h3>
        <div className="post-info">
          <p>
            <i className="fa fa-user" />
            by <a href="#">{node.frontmatter.author || 'Naomi Nadiv'}</a>
          </p>
        </div>

        <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt
          }}
        />
        <Link className="btn" to={node.fields.slug}>
          Read More <i className="fa fa-angle-right" />
        </Link>
      </div>
    </div>
  )
  /*
    return (
      <div className="post" key={node.fields.slug}>
        <Link to={node.fields.slug}>
          <Img
            className="img-fluid rounded mx-auto d-block"
            sizes={
              node.frontmatter.featuredImage.childImageSharp
                .sizes
            }
          />
        </Link>
        <div className="text">
          <span className="date">{node.frontmatter.date}</span>
          <Link to={node.fields.slug}>
            <h1>{title}</h1>
            <h3 className='text-muted text-capitalize'>{category}</h3>
            <p
              style={{ color: 'black' }}
              dangerouslySetInnerHTML={{
                __html:
                  node.frontmatter.description || node.excerpt
              }}
            />
            <div className="text-center">Read more</div>
          </Link>

          <PostTags tags={tags} />

        </div>
        <div className="social">
          <div className="share">
            <span>share</span>
            <TwitterShareButton
              url={linkUrl}
              className="d-inline"
            >
              <i className="fa fa-twitter" />
            </TwitterShareButton>
            <FacebookShareButton
              url={linkUrl}
              className="d-inline"
            >
              <i className="fa fa-facebook" />
            </FacebookShareButton>
          </div>
          <div className="comments">
            <FacebookProvider appId="634731470264758">
              <span className="d-flex flex-row">
                <CommentsCount
                  href={`${siteUrl}/${node.frontmatter.id}`}
                />
                &nbsp;comments
              </span>
            </FacebookProvider>
          </div>
        </div>
      </div>
    )
    */
}

export default postCard
