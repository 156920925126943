import React from 'react'

const postsSidebar = () => {
  return (
    <div className="sidebar col-md-3">
      <div className="well text-center">
        <div className="profile-image" />
        <h4>About Me</h4>
        <p>
          My name is Naomi Nadiv, I am ten years old, and I live in{' '}
          <a href="https://www.cityofsancarlos.org/" about="_blank">
            San Carlos, California.
          </a>
          <br />
          I have one sister, her name is Ella, and she is five years old.
          <br />I go to school at{' '}
          <a href="https://mariposa.scsdk8.org" about="_blank">
            Mariposa Upper Elementary
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default postsSidebar
